#scroll-to-top {
    position: fixed;
    bottom: 2.5rem;
    right: 2.5rem;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.2rem;
    height: 4.2rem;
    border-radius: 50%;
    font-size: 2rem;
    /* background-color: var(--primary-color); */
    background: var(--theme-gradient);
    color: white;
    cursor: pointer;
    /* transition: all .3s ease-in-out;
    opacity: 0;
    visibility: hidden; */
}