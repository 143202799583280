
footer {
    padding-top: 40px;   
    border-top: 1px solid rgba(var(--color-text), 0.1);
    color:rgb(var(--color-text));
}

/* center footer items */
.footer-categories{
    display: flex;
    justify-content: space-around;
    padding:0 clamp(1em, 5%, 15em);;      
}

.footer-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1em;

}

.footer-categories > div {
    text-align: justify;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0px;
}

.footer-header {
    font-size: 1.1rem;
    font-weight: 700;
    padding-bottom: 1rem;
}

.footer-copyright{
    padding-top: 2rem;
    text-align: center;
    opacity: .5;
    font-size: clamp(.6em, .2vw + .7em, 1.1em);
}

/* make footer categories responsive */
@media screen and (max-width: 768px) {
    .footer-categories{
        flex-direction: column;
        gap:3em;
    }
}