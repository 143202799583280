main{
    display: grid;
    grid-template-columns: 1fr;
    font-size: 20px;
    letter-spacing: -0.053em;
    line-height: 1.6;
}

.container{
  max-width: 89vw;
  justify-self: center;
  display: flex;
  padding: 2rem;
  align-items: center;
  justify-items: center;
  flex-direction: column;
}

.container-top-articles{
  width: 76vw;
  justify-self: center;
  padding: clamp(1em, 1em + .1vw, 4em) clamp(1em, 1em + .1vw, 2em);
  padding-top: 2em;

}


.container-top-articles::before{
    opacity: var(--article-header-opacity);
    z-index: -1;
    position: absolute;
    display: inline-block;
    content: '';
    height: 100%;
    width: 100%;
    top: -10%;
    background-repeat: no-repeat;
    background-position: center;
    filter: blur(1.5rem);
    background-size: 100%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    background-image: radial-gradient(circle farthest-side, var(--color-background-overlay) 0%, var(--color-background) 80% ,var(--color-background) 100%),url("http://localhost:1337/uploads/photo_1677196728306_eeafea692454_7662f89d4b.avif");
}

.title {
  /* font-weight: 700; */
  font-weight: 500;
  font-size: clamp(1.05rem, 1.2rem + 1.2vw, 1.4rem);
  /* font-size: clamp(1.2rem, 1.2rem + 1.5vw, 1.6rem); */
  /* color: var(--foreground-color-very-high); */
  color: white;
  border-bottom: 1px solid var(--color-border-line);
  line-height:0.1em; 
  margin:2.7em 0 2.2em;
  transform: skewX(-8deg);

}

.title span { 
  /* background-color: var(--color-background);  
  background-color: var(--primary-color); */
  background: rgb(91,51,112);
  background: linear-gradient(207deg, rgba(91,51,112,1) 0%, rgba(64,100,166,1) 100%);
  padding:.3em;
  padding-right:1em;
  padding-left:.5em;
  border-radius: 5px;
    box-shadow: 0 0 10px 1px #517bca75;
}

.subtitle {
  font-weight: 400;
  font-size: clamp(1.0rem, 1.0rem + .9vw, 1.4rem);
  color: var(--foreground-color-low);
}

[class*="grid-"] {
  padding-top: 1em;
    display: grid;
    gap:1.5em;
    /* padding: 20px 40px; */
  }
  
  .grid-cards{
    /* grid-auto-flow: column; */
    grid-template-columns: repeat(auto-fit, minmax(min(400px, 100%), 1fr));
    /* grid-template-columns: repeat(4, 1fr); */
  }

  
/* media query for tablet*/
@media screen and (max-width: 1200px) { 
 }
 
 
 /* media query for tablet*/
 @media screen and (max-width: 768px) {
    .container-top-articles{
  width: 100vw

    }
 }