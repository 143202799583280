
:root{
    --nav-height:75px;
    --logo-nav-height:65px;
    --space:8px;
}

nav {
    background-color: rgba(var(--color-secondary), 0.4); ;
    display: grid;
    grid-template: " . header header  .";
    border-bottom: 2px solid var(--primary-color-o1); 
}

.logo{
    cursor: pointer;
}

nav > .header-mobile {
    display: none;
    grid-template: "toggle-menu logo mode";

}

nav > .header{
    grid-area: header;
    height: var( --nav-height);
    display: grid;
    grid-template: " logo . . . . .  menu menu menu menu contact . . . . .  tools ";
    position:sticky; 
    top:0px;
    font-size: 1.05rem;
    font-weight: 600;
}

.logo {
    grid-area: logo;
    align-self: center;
    justify-self: center;
}

nav > .header > .menu {
    grid-area: menu;
    display: inline-flex;
    list-style: none;
    justify-content: space-around;
    align-items: center;
}

nav > .header > .tools {
    grid-area: tools;
    display: inline-flex;
    list-style: none;
    justify-content: space-around;
    align-items: center;
}

/* button appearance */
.tools > li > a, li > span {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-right: 0.4rem;
    padding-left: 0.4rem;
    border: var(--primary-color-o3) 1px solid;
    border-radius: 10px;
    font-size: 1.15em;
    font-weight: 500;
    margin-right: 0.2rem;
    margin-bottom: 0.2rem;
    text-transform: uppercase;
    letter-spacing: .01rem;
    cursor: pointer;
    background-color: var(--color-background-article);
}

nav > .header > .contact {
    grid-area: contact;
    align-self: center;
    justify-self: center;
}

.menu > li > a, .contact > a, .tools > li > a, .toggle-menu {
    text-decoration: none;
    color: var(--foreground-color-high);
    letter-spacing: .01rem;
    /* smallcaps*/
    /* font-variant: small-caps; */
    /* uppercase */
    text-transform: uppercase;
    font-weight: 600;    
    z-index: 2;
}

.menu > li > a {
    font-size: .95rem;
}

.menu > li > a::after {
    content: '';
    display: flex;
    position: relative;
    bottom: 7px;
    width: 100%;
    transform: translate(5%);
    height: 7px;
    background-color: var(--primary-color-o3);
    z-index: 0;
    transition: opacity 0.4s ease;
    opacity: 0;
} 

.menu > li > a:hover::after {
    opacity: 1;
}

.menu > li > a::before {
    content: '';
    display: block;
    position: relative;
    padding-top: 5px;
} 

a > svg{
    top: 2px;
    position: relative;
}

.logo > a > img {
    height: var( --logo-nav-height);
    object-fit: cover;
    padding:.5rem;
}

/* media queries for tablet */
@media (max-width: 1300px) {
    nav {
        grid-template: "header";
    }

    nav > .header {
        grid-template: "logo . . menu menu menu menu . contact tools ";
    }
}

/* media queries for mobile */
@media (max-width: 768px) {
   .menu {
    flex-direction: column;
    gap:.8em;
    /* space on top and bottom of this element */
    padding: .6em 0;
    
   }

   .mode {
    grid-area: mode;
    align-items: center;
    display: flex;
    color:var(--color-foreground);
    font-size: 1.1rem;
    padding: 1rem;
   }

   .logo > a > img {
    height: calc(var( --logo-nav-height) - .5em);
    object-fit: cover;
    padding:.5rem;
}

   nav > .header-mobile {
    display: grid;
    justify-content: space-between;
   }
    nav > .header {
        /* height: auto; */
        display: none;
    }

  .toggle-menu {
    grid-area: toggle-menu;  
    cursor: pointer;
    background: transparent;;
    border: none;
    font-size: 1.8rem;
    margin-left: .7em;
  }

  .menu-mobile {
    margin-top:3em;
    list-style: none;
    position: absolute;
    background: var(--color-background);
    width: 0;
    overflow: hidden;
    max-width: 13em;
    border-top-right-radius: .4rem;
    border-bottom-right-radius: .4rem;
    z-index: 9;
    font-size: 1.1em;
    box-shadow: 0 .2rem 0.2rem 0.2rem var(--light-shadow);
    transform: translateX(-18em);
    transition: transform ease-in-out 0.3s;
    /* transition: width ease 0.2s; */
  }

  .menu-mobile.show-menu {
    width: 100%;
    transform: translateX(0px);
  }
  
  
  .menu-mobile li a, .menu-mobile li span {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    text-decoration: none;
    color:var(--foreground-color);
  }
}