@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poltawski+Nowy:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10..0,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jersey+25&display=swap');
:root{
    --hr-color:rgba(253, 253, 253, 0.31);

    --grey-lines-color: rgba(199, 199, 204, 0.308);
    --primary-color:rgb(64, 22, 119);
    --primary-color-title:rgb(46, 45, 48);
    --primary-color-o1:none;
    --primary-color-o3:rgba(64, 22, 119,0.1);
    --primary-color-o8:rgba(64, 22, 119,0.8);

    --light-shadow:rgba(0, 0, 0, 0.19);

    --color-secondary:255,255,255;
    --color-background-overlay:#00000000;
    --color-background-scroll-button-overlay:#0f152241;

    --foreground-color-very-high:rgb(59, 59, 59);
    --foreground-color-high:#4b4b4b;
    --foreground-color-medium:#6b6b6b;
    --foreground-color-low:#8b8b8b;

    --color-text:19, 19, 19;
    --color-text-lighter:rgb(56, 56, 56, 0.9);
    --color-background: #fbfbfb;
    --color-background: #fbfbfb;
    --color-background-article: #fbfbfb;
    
    --article-header-opacity:.31;
    
    
    --color-background-card: #0b101a;
    --color-text-card: #fbfbfb;
    --color-border-line:rgba(31, 21, 44, 0.16);
    --color-border-card:rgba(31, 21, 44, 0.16);
    --color-border-card-outline:rgba(74, 12, 150, 0.05);

    --top-article-height:19em;

    --theme-gradient: linear-gradient(207deg, rgba(91,51,112,1) 0%, rgba(64,100,166,1) 100%);

}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;    
}

h1 {
    font-size: 6rem;
    letter-spacing: -0.069em;
    font-weight: 680;
}

header {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 15px 10px -17px var(--light-shadow);
}

hr {    
    margin: 1.2rem 0;
    color: var(--hr-color);
}

body {
    /*font-family:  'Be Vietnam Pro','Noto Sans','Inter', sans-serif;*/
    font-family:  'Inter','Be Vietnam Pro','Noto Sans', sans-serif;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr  auto;
    gap:10px;
    background-color: var(--color-background);
    
}

p, h1, h2, h3, h4, h5, h6, th, td {
    color:rgb(var(--color-text));
}

p {
    font-size: clamp(.7rem, 0.5vw + 0.74rem, 1.075rem);
    font-weight: 300;
    letter-spacing: .0004rem;
}

li {
    margin-left: clamp(.7rem, 0.5vw + 0.8rem, 1.1875rem);
    font-size: clamp(.7rem, 0.5vw + 0.8rem, 1.1875rem);
    font-weight: 400;
    letter-spacing: .0004rem;
    color:rgb(var(--color-text));
}

li {
    color:rgb(var(--color-text));
}

body.dark {

    --hr-color:rgba(253, 253, 253, 0.31);
    --color-background: #050606;
    /*--color-background-article:#131b2c;*/
    --color-background-article: #040506;

    --color-foreground: #efefef;

    --foreground-color-very-high:rgb(219, 219, 219);
    --foreground-color-high: var(--color-text-lighter);
    --primary-color-o3:#efefef1f;
    /* --primary-color-o3:#efefefc7; */

    --light-shadow:rgba(0, 0, 0, 0.3);
    --color-text:255, 255, 255;

    --primary-color-title:rgb(255, 255, 255);
    --color-text-lighter:rgb(212, 205, 219);

/*    --color-secondary:#0b101a;*/
    --color-secondary:4,5,6;
    --color-background-overlay:#0f1522ea;
    --color-background-scroll-button-overlay:#a79c9c6c;
    --primary-color-o1:none;

    --article-header-opacity:1;

    --color-border-card:rgba(31, 21, 44, 0.418);
    --color-border-line:rgba(235, 235, 235, 0.164);

    }
/* 
@media (prefers-color-scheme: dark) {
    body {
   
    }
} */