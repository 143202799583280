  .card {
    border-radius: 8px;
    display: grid;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: var(--color-background-card);
  }

  .card-wrapper {
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    overflow: hidden;
    /*background: var(--theme-gradient);*/
    background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(134,0,144,1) 0%, rgba(0,51,177,0.6544818611038166) 50%, rgba(3,124,189,0.24831939611782217) 100%);
    padding: .09em;
    /*border: 1px solid var(--color-border-card-outline);*/
    box-shadow: 0 0 10px 1px rgba(81, 123, 202, 0.18);
  }

  .classic-card {
    background-size: cover;
    background-position: center;
  }


  .other-articles {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-top: 4rem;
  }

  .other-articles > .individual-article{
    grid-template-columns: 2fr 4fr;
    display: grid;
    grid-column-gap: 2rem;
    color:rgb(var(--color-text));
  }

  .other-articles > .individual-article > .card-presentation{
    border-radius: 4px;
    display: grid;
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  .other-articles > .individual-article  > .other-article-content{
    display: flex;
    flex-direction: column;
    gap:1rem;
  }

  .other-articles > .individual-article   > .other-article-content > .other-article-published{
    text-transform: uppercase;
    color: var(--color-text-lighter);
    font-weight: 300;
    font-size: clamp(.8rem, 0.4vw + 0.8rem, 1.0rem);
    letter-spacing: .005rem;
  }

  .other-articles > .individual-article   > .other-article-content > .other-article-description{
    font-size: clamp(.9rem, 0.7vw + 1rem, 1.2rem);
    font-weight: 400;
  }

  /* Style pour la barre d'onglets */
  .react-tabs__tab-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center; /* Centre les onglets horizontalement */
  }

  /* Style commun pour tous les onglets */
  .react-tabs__tab {
    font-size: clamp(1.8rem, 0.4vw + 1.8rem, 2.6rem);
    font-family: "Jersey 25", sans-serif;
    padding: 5px 10px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: #4f1de3; /* Couleur par défaut des onglets */
    outline: none;
    transition: color 0.3s;
  }

  /* Style pour l'onglet actif */
  .react-tabs__tab--selected {
    color: #6e42ec;
    font-weight: bold;
    border-bottom: 2px solid rgba(64, 0, 255, 0.37); /* Ajoute une bordure en bas pour l'onglet actif */
  }


  .card-top {    
    height: var(--top-article-height);
    grid-template-areas: "card-image card-content";

  }

  .card-top > .card-content{
    padding: 1em;
    color: var(--color-text-card);
    gap:.6em
  }  

  .card-image {
    grid-area: card-image;
    /* height: 100%; */
    position: relative;
    z-index: 1;
  }

  
  .card-image > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

  }

  .card-top > .card-content{
    height: var(--top-article-height);
    background-color: var(--color-background-card);
    grid-area: card-content;
  }

  .card-content {
    padding: 1em;
    display: grid;
    /* grid-template-rows: 1em auto 1em 4fr; */
    flex: 0 0 50%;
    position: relative;
    overflow: hidden;
    z-index: 2;
    gap:1em;
  }

  .card-image::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 5em;
    height: 100%;
    background-color: var(--color-background-card);
    transform-origin: right top;
    transform:  skew(15deg);
    z-index: 1;
  }

  

  .card-tag {
    display: flex;
    font-weight: 300;
    font-size: clamp(.8rem, .8rem + .7vw, 1.2rem);
    color: var(--color-text-card);
    font-variant-caps: all-petite-caps;
    letter-spacing: 0.043em;
  }

  .card-top > * > .card-content-heading{
    padding-top: 1rem;
  }
  .card-top > * > .card-content-heading > .heading-title {
    /*font-family: 'Poltawski Nowy', serif;*/
    font-size: clamp(1.9rem, 1.9rem + .8vw, 3.1rem);
    font-weight: 800;
    /* padding: 1rem 0; */
    color: var(--color-text-card);
    line-height: 1.4;
  }

  .classic-card > * > .card-content-heading{
    font-size: clamp(1.1rem, 1.1rem + .6vw, 1.7rem);
    text-shadow: #100a5b 1px 0 10px;
    font-weight: 700;
    color: var(--color-text-card);
  }


  .heading-published {
    font-weight: 300;
    font-size: clamp(.7em, .7em + .5vw, .8em);
    text-transform: uppercase;
    color: var(--color-text-card);
    letter-spacing: .005rem;
  }

  
  .card-body {
    color: var(--color-text-card);
  }

  .card-content-description {
    font-size: clamp(.6em, .6em + .7vw, .95em);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 300;
    letter-spacing: 0rem;
  }

  .card-button {
    display: flex;
    justify-content: right;
  }

  .card-button > button {
    all: unset;
    align-self: center;
    background-color: white;

    padding: clamp(.25em, .25em + .2vw, .30em) clamp(.8em, .8em + .6vw, 1.1em);
    font-size: clamp(.7em, .7em + .6vw, .9em);
    font-weight: 500;
    letter-spacing: 0.023em;
    /* border-radius: 5px; */
    border-radius: 6px;
    /* color: #5c7e8a; */
    /* text-shadow: #99bfcc 0 0 10px; */
    filter: drop-shadow(0 0 .2rem rgba(0, 0, 0, 0.2));
    cursor: pointer;
    border: .001em solid #ffffff24;    
    transition: 0.6s;
  }

  .color-top > button > a{
    color: #5c7e8a;
    transition: 0.5s;

  }

  .color-top > button:hover > a{
    color: #eeeeee;
  }

  .color-classic > button {
    background: rgba(255, 255, 255, 0.2);
    /*border-radius: 16px;*/
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  .color-classic > button > a {
    color: #acc3c9;
    font-weight: 600;
    transition: 0.5s;
  }

  .color-classic > button:hover > a {
    color: #eeeeee;

  }

  .card-button > button > a {
    text-decoration: none;
    /*font-family: "Jersey 25", sans-serif;*/
    text-transform: uppercase;
    font-weight: 800;

  }

   .card-button > button:hover {background: var(--theme-gradient);}

   .card-button > button:active {
    background-color: var(--primary-color-o8);
    box-shadow: 0 5px #dcebf0;
    transform: translateY(4px);
  }

  /* media query for tablet */
  @media screen and (max-width: 1400px) {

    .other-articles > .individual-article{
      grid-template-columns: 2fr 2fr;
    }
    /* .card-content{
    padding-left:-10em;

    }
  .card-image::after {
    width: 15em;
  } */

  }
  
  /* media queries for mobile */
  @media screen and (max-width: 1050px) {
    .card-top {
      height: auto;
      display: grid;
      grid-template-areas: " card-image " " card-content ";
    }


    .other-articles > .individual-article{
      grid-template-columns: 2fr;
      grid-template-rows: 2fr;
    }

    .card-top > .card-image{
      height: 10em;
    }

    .card-top > .card-image::after{
      content:none;
    }

    .card-wrapper{
      padding:.2em;
    }

    .card-top > .card-content{
      grid-template-rows: 1fr 2fr 1fr;
    }

    .other-articles > .individual-article > .other-article-content {
      gap: 1rem;
      padding: 1rem;
    }

  }